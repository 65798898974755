((window, document) => {
  const brandIds = {
    Taxi: [2603],
    VD: [2801],
    VMC: [3163, 3164, 3269],
  };

  const url = window.location.hostname;
  const onlineReviews = window.location.href.includes('buy-online');
  const sellMyCarReviews = window.location.href.includes('sell-my-car');

  function getCurrentBrand() {
    let result = '';
    if (url.includes('thetaxicentre') || url.includes('ttc.web')) {
      result = 'Taxi';
    } else if (url.includes('vansdirect') || url.includes('vd.web')) {
      result = 'VD';
    } else if (url.includes('vertumotorcycles') || url.includes('vmc.web')) {
      result = 'VMC';
    }
    return result;
  }

  function initialize() {
    let idString = '';
    const brandArr = brandIds[getCurrentBrand()];
    brandArr.forEach((value) => {
      idString = `${idString + value}|`;
    });

    let surveyIdString = '&surveyId=2';

    if (onlineReviews) {
      surveyIdString = '&surveyId=2|164';
    } else if (url.includes('vansdirect') || url.includes('vd.web')) {
      surveyIdString = '';
    } else if (url.includes('vertumotorcycles')) {
      surveyIdString = '';
    } else if (url.includes('thetaxicentre')) {
      surveyIdString = '&surveyId=97';
    } else if (sellMyCarReviews) {
      surveyIdString = '&surveyId=227';
    }

    let urlString;

    urlString =
      'https://js-api.scdn5.secure.raxcdn.com/testimonials.jsonp?key=06b875770769debf92e44c77dc7696df7e9d00b4&dealerId=';
    urlString = `${urlString}${idString}&callback=judgeServiceCTCallback${surveyIdString}`;
    if (sellMyCarReviews) {
      urlString = `${urlString}&limit=4&q[24]=Completely%20Satisfied&testimonialContains=amazing|efficient|happy.`;
    }
    // eslint-disable-next-line no-use-before-define
    window.judgeServiceCTCallback = injectValues;
    // eslint-disable-next-line no-use-before-define
    attachScript(urlString);
  }

  function attachScript(uriString) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = uriString;
    document.body.appendChild(script);
  }

  function buildReviewSchema(data) {
    const schemaContainer = document.createElement('script');
    schemaContainer.setAttribute('type', 'application/ld+json');
    // Build schema object
    const schemaData = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: 'Sell My Car',
      review: data.testimonials.map((review) => ({
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue:
            review.satisfaction_score_out_of_5 === 0 ? 4 : review.satisfaction_score_out_of_5,
          bestRating: data.avg_satisfaction_out_of_5 === 0 ? 5 : data.avg_satisfaction_out_of_5,
        },
        author: {
          '@type': 'Person',
          name: review.author,
        },
        description: review.testimonial,
      })),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: data.avg_satisfaction_out_of_5 === 0 ? 5 : data.avg_satisfaction_out_of_5,
        reviewCount: data.count,
      },
    };
    schemaContainer.innerHTML = JSON.stringify(schemaData);
    document.head.appendChild(schemaContainer);
  }

  function selectVisibleTestimonials(data) {
    let selectedFour = [];
    data.sort((a, b) => (a.satisfaction_percent > b.satisfaction_percent ? -1 : 1));

    // Select the testimonials to be displayed
    function selectTestimonials(restrictLength) {
      const selectedDealers = [];
      const selectedSubCategories = [];
      const selectedReviews = [];
      if (restrictLength) {
        data = data.filter((review) => review.testimonial.length < 300);
      }
      if (onlineReviews) {
        const randomisedData = [].concat(data);
        randomisedData.sort(() => 0.5 - Math.random());
        data = randomisedData;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const review of data) {
        selectedReviews.push(review);
        selectedDealers.push(review.id);
        selectedSubCategories.push(review.dealer_secondary_category);
        if (onlineReviews) {
          if (selectedReviews.length === 10) {
            break;
          }
        } else if (!onlineReviews) {
          if (selectedReviews.length === 4) {
            break;
          }
        }
      }
      return selectedReviews;
    }
    // Get four reviews, but filter out particularly lengthy tesimonials
    selectedFour = selectTestimonials(true);

    // If this returns less than 4 reviews, rerun the function without filtering
    if (selectedFour.length < 4) {
      selectedFour = selectTestimonials(false);
    }
    return selectedFour;
  }

  function injectValues(data) {
    // eslint-disable-next-line no-use-before-define
    if (sellMyCarReviews) {
      buildReviewSchema(data);
    }
    const testimonials = selectVisibleTestimonials(data.testimonials);
    const satisfactionPercent = Math.floor(data.recommend_percent);
    const testimonialsBox = document.getElementsByClassName('js-homepage-testimonials')[0];
    const starBox = document.getElementsByClassName('js-customer-testimonial-stars-box')[0];
    let testimonialsUI = '';
    if (onlineReviews) {
      testimonialsBox.classList.add('padding-right-0');
    }
    const stars = `<div class="star__box" style="width: ${satisfactionPercent}px;"><div style="width: 200%; white-space: nowrap;"><svg class="icon"><use xlink:href="#globalStar"></use></svg><svg class="icon"><use xlink:href="#globalStar"></use></svg><svg class="icon"><use xlink:href="#globalStar"></use></svg><svg class="icon"><use xlink:href="#globalStar"></use></svg><svg class="icon"><use xlink:href="#globalStar"></use></svg></div></div>`;
    testimonials.forEach((testimonial) => {
      testimonialsUI = `${testimonialsUI}
      <div class="xs-col-12 s-col-6 l-col-3">
        <div class="customer-testimonial__slide">
            <blockquote class="customer-testimonial__quote">
              "${testimonial.testimonial}"
            </blockquote>
              <cite class="customer-testimonial__attribution">
                  <div class="customer-testimonial__name">${testimonial.author}</div>
                  <div class="customer-testimonial__location">${testimonial.dealer_name}</div>
              </cite>
            </div>
      </div>`;
    });

    if (testimonialsBox && starBox) {
      testimonialsBox.innerHTML = testimonialsUI;
      starBox.innerHTML = `${stars}<div class="hide--mobile-up customer-testimonial__logo-box margin-bottom-15">
      <svg class="customer-testimonial__logo icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 474.6 63.5" style="enable-background:new 0 0 474.6 63.5;" xml:space="preserve">
  <path  fill="currentColor" d="M30.9,2H13.8c-0.9,0-1.6,0.7-1.6,1.6v3.9c0,0.9,0.7,1.6,1.6,1.6h3.6c0.7,0.4,0.9,1.2,0.9,2.5
     c-0.1,8.5,0,17-0.1,25.6c0,1.2-0.1,2.5-0.3,3.7c-0.5,2.9-2,4.5-5.1,4.7c-3.4,0.2-6.5-0.6-9.5-2.3c-1-0.6-1.9-1.6-3.3-1.3
     c0,2.5,0,5,0.1,7.5c5,2.9,10.5,4,16.1,3c6.7-1.2,9.1-3.7,10.7-10.2c0.5-2.1,0.7-4.3,0.7-6.5c-0.1-8.1,0-16.3-0.1-24.4
     c0-1.2,0.2-2,0.9-2.3h2.5c0.9,0,1.6-0.7,1.6-1.6V3.6C32.5,2.7,31.8,2,30.9,2z M69.6,45.5H67c-0.5-0.3-0.7-1-0.7-2.1
     c0.1-7.4,0-14.8,0.1-22.3l0,0v-3.4c0-0.9-0.7-1.6-1.6-1.6H54.4c-0.9,0-1.6,0.7-1.6,1.6v0.6c0,0.3-0.1,0.6-0.1,0.9
     c0,0.4,0,0.9,0.1,1.3v0.6c0,0.9,0.7,1.6,1.6,1.6h2.8c0.3,0.3,0.4,0.9,0.4,1.6c-0.1,4.2,0,8.3-0.1,12.5c0,2-0.3,4-1.3,5.8
     c-1.4,2.6-4,3.8-7.2,3.2c-2.7-0.5-3.3-2.5-3.6-4.8c-0.2-1.2-0.2-2.5-0.2-3.7c0-5.4,0-10.7,0-16.1v-0.1v-3.4c0-0.9-0.7-1.6-1.6-1.6
     H33.2c-0.9,0-1.6,0.7-1.6,1.6v0.1c-0.1,0.6,0,1.2,0,1.7c0,0.4,0,0.8,0,1.2v0.4c0,0.9,0.7,1.6,1.6,1.6h2.5c0.6,0.6,0.6,1.6,0.6,2.9
     c-0.1,4.6-0.1,9.3,0,13.9c0,1.3,0.1,2.6,0.3,4c0.9,5.9,4.7,8.6,9.9,9.3c4.5,0.6,8.3-0.9,11.9-4.3v2c0,0.9,0.7,1.6,1.6,1.6h9.7
     c0.9,0,1.6-0.7,1.6-1.6v-3.4C71.2,46.2,70.5,45.5,69.6,45.5z M108,45.7h-2.5c-0.5-0.7-0.5-1.9-0.5-3c0.1-12.5,0-25.1,0.1-37.6l0,0
     V1.6c0-0.9-0.7-1.6-1.6-1.6H93.1c-0.9,0-1.6,0.7-1.6,1.6V5c0,0.9,0.7,1.6,1.6,1.6H96c0,0.1,0.1,0.3,0.1,0.5c0.1,3.8,0,7.6,0,11.7
     c-7.8-6.4-19.5-3.3-22.9,7.1c-1.8,5.5-1.7,11,0,16.5c2.2,7.1,7.6,10.7,15,10.4c3.3-0.2,5.8-1.8,8.3-4.2c0.2,0.4,0.2,0.8,0.3,1.1v0.9
     c0,0.9,0.7,1.6,1.6,1.6h9.7c0.9,0,1.6-0.7,1.6-1.6v-3.4C109.6,46.4,108.9,45.7,108,45.7z M95.2,41.3c-1.1,2.8-3.1,4.7-6.4,4.7
     s-5.2-1.9-6.4-4.7c-0.9-2.2-1.2-4.6-1.1-7c0.1-2.6,0.3-5.3,1.3-7.8c1.2-2.7,3.1-4.2,6.1-4.3s5.1,1.3,6.2,4
     C97,31.2,97.2,36.3,95.2,41.3z M146.8,15.5h-10.2c-0.9,0-1.6,0.7-1.6,1.6v1.7c-7.6-6.6-18.3-4.5-22.5,4.3
     c-2.5,5.2-2.4,10.6-1.4,16.1c2.2,11.8,15.6,15.9,23.2,8.5c0.4,5.4-1.8,8.3-6.9,9c-4.6,0.6-8.8-0.6-12.7-2.9c-1.7-1-2-0.5-2,1.3
     c-0.1,5.9-0.2,6,5.8,7.4c1.6,0.4,3.1,0.7,4.7,0.9c12.3,1,20.7-3,20.4-18.1c-0.1-7,0-14-0.1-21c0-1.1,0.2-1.8,0.8-2.2h2.4
     c0.9,0,1.6-0.7,1.6-1.6v-3.4C148.4,16.2,147.7,15.5,146.8,15.5z M132.8,41.5c-3,4.1-8.6,4.1-11.1-0.3c-3-5.4-2.9-11.2,0.1-16.6
     c2.4-4.4,8.9-3.9,11.5,0.5c1.4,2.5,1.6,5.2,1.7,8.2C135,36.1,134.6,39,132.8,41.5z M166.6,46.1c-9,0-9.3-10.1-9.3-10.1h23
     c0-12.6-3.8-16.7-3.8-16.7s-3.4-4.7-11.8-4.7c-8.4,0-11.5,3.9-11.5,3.9s-5.3,5.5-5.3,13.7s3.5,13.5,3.5,13.5s4.9,7,13.8,7
     c8.1,0,13.1-3,13.1-3v-7.9C178.3,42,175.6,46.1,166.6,46.1z M164.4,20.8c7.8,0,6.7,9.4,6.7,9.4h-13.8
     C157.3,30.2,156.6,20.8,164.4,20.8z M377.5,15.4H373c-0.5,0-1,0.4-1,1v1.8c-0.6-1-2.6-3.2-8.1-3.2c-7.2,0-10.4,3.8-10.4,3.8
     s-5.5,6.6-5.5,14.1s3,12.4,3,12.4s4,7.5,14.1,7.5s13-3.9,13-3.9v-8.3c0,0-7,5.2-11.6,5.2s-7.2-3.6-7.2-3.6c-3-2.7-2.2-12.8-2.2-12.8
     s1.5-7.8,7.9-7.8c4.8,0,6.4,3.1,6.9,4.7v4.1c0,0.5,0.4,1,1,1h4.5c0.5,0,1-0.4,1-1v-14C378.5,15.9,378,15.4,377.5,15.4z M400.6,46.1
     c-9,0-9.3-10.1-9.3-10.1h23c0-12.6-3.8-16.7-3.8-16.7s-3.4-4.7-11.8-4.7s-11.5,3.9-11.5,3.9s-5.3,5.5-5.3,13.7s3.5,13.5,3.5,13.5
     s4.9,7,13.8,7c8.1,0,13.1-3,13.1-3v-7.9C412.3,42,409.6,46.1,400.6,46.1z M398.4,20.8c7.8,0,6.7,9.4,6.7,9.4h-13.8
     C391.3,30.2,390.6,20.8,398.4,20.8z M245.3,46.1c-9,0-9.3-10.1-9.3-10.1h23c0-12.6-3.8-16.7-3.8-16.7s-3.4-4.7-11.8-4.7
     s-11.5,3.9-11.5,3.9s-5.3,5.5-5.3,13.7s3.5,13.5,3.5,13.5s4.9,7,13.8,7c8.1,0,13.1-3,13.1-3v-7.9C257,42,254.3,46.1,245.3,46.1z
      M243.1,20.8c7.8,0,6.7,9.4,6.7,9.4H236C235.9,30.2,235.3,20.8,243.1,20.8z M281.4,16.6c-3.7,0-7.4,3.7-8.5,4.8v-4.8h-3h-4.6h-3.9
     c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h3.9v23.3h-3.6c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h16.8c0.5,0,1-0.4,1-1v-4.5
     c0-0.5-0.4-1-1-1h-4.4V30.5c0.3-1.9,4.1-6.9,7.1-6.9s3.6,1.2,3.6,1.2l0.9-7.3C285.6,17.5,285.7,16.6,281.4,16.6z M338.6,0.4
     c3.2,0,5.8,2.6,5.8,5.8s-2.6,5.8-5.8,5.8s-5.8-2.6-5.8-5.8S335.4,0.4,338.6,0.4z M347.1,46.4h-3.3V16.6h-3.7H335h-3.3
     c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h3.3v23.3h-3.4c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h15.5c0.5,0,1-0.4,1-1v-4.5
     C348.1,46.8,347.6,46.4,347.1,46.4z M326.8,17h-14.7c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h3.6l-7,20.5l-6.9-20.5h3.3
     c0.5,0,1-0.4,1-1V18c0-0.5-0.4-1-1-1h-14.7c-0.5,0-1,0.4-1,1v4.5c0,0.5,0.4,1,1,1h2.7l10.6,29.4h9.3l10.9-29.4h2.9c0.5,0,1-0.4,1-1
     V18C327.8,17.4,327.3,17,326.8,17z M439.9,35.6c3.7-0.7,6.9-2.3,9.6-5c3.4-3.5,5.2-7.6,5.2-12.5c0.1-4.9-1.9-9.2-5.2-12.6
     c-4.1-4.4-9.5-6-15.4-5.1c-7,1-15.2,6.8-15.2,17.7c0,4.8,1.7,9.2,5.2,12.6C428.6,34.9,433.8,36.7,439.9,35.6z M435.9,4.5
     C443.6,4,450,10,450.5,17.4c0.5,7.3-6.1,14.5-13.2,14.3c-7.2,0.5-13.6-5.8-14-12.7C422.8,10.9,429.1,4.9,435.9,4.5z M465.1,18.9
     c5.3,0,9.5-4.1,9.5-9.4c0-5.1-4.1-9.1-9.1-9.3s-9.6,3.7-9.7,9.3C455.8,14.6,460,18.8,465.1,18.9z M465.3,2.4c3.9,0,7.1,3.3,7.1,7.1
     c0,4-3.2,7.1-7.1,7.1s-7.1-3.2-7.1-7.1C458.1,5.7,461.5,2.4,465.3,2.4z M437,25.6c0.3,0,0.9-0.1,1.4-0.2c2.8-0.7,5-2.7,5.7-5.5
     c0.1-0.6,0.1-1.2-0.6-1.3c-0.5-0.1-1.1,0.1-1.2,0.8c-0.1,0.4-0.4,0.8-0.6,1.2c-1.1,2.1-3.2,3.3-5.3,3.1c-2-0.2-4.4-1.6-5-4
     c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.4-0.5-0.7-1-0.5c-0.5,0.1-0.8,0.5-0.7,1c0,0.3,0.1,0.6,0.2,0.9C430.7,23.5,433.4,25.5,437,25.6z
      M461.7,13.4c0.7,0.3,0.7-0.5,0.8-0.8c0.8-2,3.3-2.4,4.8-0.9c0.4,0.4,0.7,0.9,0.8,1.4c0.1,0.3,0.3,0.5,0.6,0.4
     c0.3-0.1,0.4-0.3,0.4-0.7c0-0.1-0.1-0.2-0.1-0.3c-0.5-1.6-2-2.7-3.7-2.7c-1.5,0-3,0.9-3.6,2.4C461.5,12.5,461.1,13.1,461.7,13.4z
      M221.7,29.5c-2.6-2.9-6.3-4.7-10-6c-4.3-1.5-8.8-2.4-13.1-3.9c-4.5-1.6-5.4-6.9-1.7-9.8c3.7-2.9,7.8-2.7,11.9-1.1
     c2.9,1.1,4.6,3.1,5.2,5.8v4.8c0,0.5,0.4,1,1,1h5.6c0.5,0,1-0.4,1-1V2.1c0-0.5-0.4-1-1-1H218c-0.4,0-0.8,0-1.1,0h-2c-0.5,0-1,0.4-1,1
     v0.7c-2-0.7-4.9-1.5-7.7-1.9c-0.2,0-0.4-0.1-0.5-0.1c-1.1-0.2-2.2-0.2-3.2-0.2c-4.6,0.1-8.8,1.8-12.7,5.3
     c-4.7,4.2-5.4,14.8-0.5,18.5c3,2.2,6.2,4.2,10,5.1c4,1,8,1.8,11.7,3.7c2.6,1.3,3.4,3.5,3.5,6c0,2.6-1.5,4.4-3.9,5.5
     c-4,1.8-8,1.5-12,0c-4.6-1.7-6.5-4.2-6.3-9c0-0.4,0-0.8-0.1-1.1v-0.9c0-0.5-0.4-1-1-1h-1.4c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0h-1.9
     c-0.5,0-1,0.4-1,1v17.1c0,0.3,0.2,0.6,0.4,0.8v0.1h0.2c0.1,0.1,0.3,0.1,0.4,0.1h5.6c0.5,0,1-0.4,1-1v-1.9c1.7,0.9,3,2.2,4.8,2.7
     c9.9,3.5,17.8,0,17.8,0c2.3-1.4,4.8-2.7,6.5-5C224.2,42.4,224.9,33.1,221.7,29.5z"/>
    </svg>
    </div>`;
    }

    const percentageRatings = document.getElementsByClassName('js-customer-testimonial-percent');
    Array.from(percentageRatings).forEach((element) => {
      element.innerHTML = satisfactionPercent;
    });
  }

  initialize();
})(window, document);
